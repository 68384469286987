import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AccoxiModule } from './accoxi/accoxi.module';
import { NoPageFoundComponent } from './no-page-found/no-page-found.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full',
  }, {
    path: '',
    loadChildren: './accoxi/accoxi.module#AccoxiModule'
  },
  {
    path: '**',
    component: NoPageFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
