import { Component, OnInit } from '@angular/core';
import { Title, Meta, DomSanitizer } from '@angular/platform-browser';

import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-no-page-found',
  templateUrl: './no-page-found.component.html',
  styleUrls: ['./no-page-found.component.scss']
})
export class NoPageFoundComponent implements OnInit {

  url: any;
  pageData: any;

  constructor(
    private title: Title,
    private storage: LocalStorageService,
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.title.setTitle('No Page Found');
    })
    this.url = window.location.hostname;

    this.storage.observe('bisnessdetails')
      .subscribe((res:any) => {
        this.pageData = res;
      });
    this.pageData = this.storage.retrieve('bisnessdetails');
  }

  removeWhiteSpace(link) {
    if (link) {
      return link.split(" ").join("")
    }
    return '';
  }

}
