import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LocalStorageService } from 'ngx-webstorage';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private storage: LocalStorageService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (this.storage.retrieve('user') && this.storage.retrieve('user')['accessToken']) {
      const authToken = this.storage.retrieve('user')['accessToken'];
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`
        }
      });
    }
    return next.handle(request);

  }

}
