// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  apiUrl: 'https://ecommerceapi.accoxi.com',
  paymentRedirectUrl: 'https://ecommerceapi.accoxi.com/payment-response',
  paymentMode: 'LIVE',
  mediaUrl: 'http://accoxi.aabapps.com',

  // apiUrl: 'http://mockup.aabasoft.info/accoxiecomapi',
  // paymentRedirectUrl: 'http://mockup.aabasoft.info/accoxiecomapi/payment-response',
  // paymentMode: 'TEST',
  // mediaUrl: 'http://accoxi.aabapps.com',

  pageSize: 24,
  businessId: 105,
  branchId: 140,

  currency: 'INR',
  emPayApiKey: '01113598-0efc-49a1-853e-183fb39c7861',
  emPaySalt: '45d3e30c7b244ce6f496293d6d3dbd963922c060',

  // paymentRedirectUrl: 'http://localhost:50146/payment-response',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
