import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';

import { LocalStorageService } from 'ngx-webstorage';

const URL = `${environment.apiUrl}`;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private authService: AppService,
    private http: HttpClient,
    private storage: LocalStorageService,
    private router: Router
  ) { }

  intercept(request, next) {
    return next.handle(request).pipe(catchError(err => {

      if (err.status === 401) {
        // this.router.navigate(['/auth/login']);
        return this.handle401Error(request, next);
      }

      const error = err.error || '';
      return throwError(error);
    }));
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    return this.authService.geRrefreshToken().pipe(
      switchMap((token: any) => {
        const authToken = this.storage.retrieve('user')['accessToken'];
        if (authToken) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${authToken}`
            }
          });
        }
        return next.handle(request);
        // .pipe(catchError(err => {
        //   const error = err.error || '';
        //   return throwError(error);
        // }));
      }));
  }

}
