import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend,  } from '@angular/common/http';
import { Router } from '@angular/router';

import { Subject, BehaviorSubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';

import { environment } from '../environments/environment';

const URL = `${environment.apiUrl}`;

@Injectable()

export class AppService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private handler: HttpBackend,
    private storage: LocalStorageService
  ) {
      this.http = new HttpClient(handler);
  }


  geRrefreshToken() {

    const data = {
      customerId: this.storage.retrieve('user')['customerId'],
      email: this.storage.retrieve('user')['email'],
      refreshToken: this.storage.retrieve('user')['refreshToken']
    };

    return this.http.post<any>(`${URL}/get-access-token`, data)
      .pipe(tap((response: any) => {
          if (response['accessToken']) {
            const mRes = {
              accessToken: response.accessToken,
              refreshToken: response.refreshToken,
              customerId: response.customerDetails.pk_intPersonalAccountAutoID,
              email: response.customerDetails.vaContactPersonMailID
            };
            this.storage.store('user', mRes);
          } else {
            this.storage.clear();
            // this.toastr.error('Token Expired')
            this.router.navigate(['/account/login']);
          }
        },
        (err) => {
          this.storage.clear();
          this.router.navigate(['/account/login']);
        }
      ));
  }

  // Error handling
  handleError(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = error.error;
     }
     return throwError(errorMessage);
  }

}
