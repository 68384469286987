import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'accoxi';

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private storage: LocalStorageService){
  }

  ngOnInit(): void {
    this.storage.observe('bisnessdetails')
      .subscribe((res:any) => {
        this.setTitle();
      });
    this.setTitle();
  }

  setTitle() {
    if (this.storage.retrieve('bisnessdetails')) {
      if (this.storage.retrieve('bisnessdetails')['FavIconPath']) {
        // this._document.getElementById('appFavicon').setAttribute('href', this.storage.retrieve('bisnessdetails')['FavIconPath']);
        const link = document.createElement('link');
        link.rel = 'icon';
        link.type = 'image/x-icon';
        link.href = this.storage.retrieve('bisnessdetails')['FavIconPath'];
        document.head.appendChild(link);
      }
    }
  }

}
